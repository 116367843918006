@font-face {
  font-family: "Lovelo";
  src: url("./assets/fonts/Lovelo-Black.ttf") format("truetype"),
    url("./assets/fonts/Lovelo-Black.woff") format("woff"),
    url("./assets/fonts/Lovelo-Black.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TW-Cen-MT";
  src: url("./assets/fonts/Tw-Cen-MT.ttf") format("truetype"),
    url("./assets/fonts/Tw-Cen-MT.woff") format("woff"),
    url("./assets/fonts/Tw-Cen-MT.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

html,
body {
  height: 100%;
  margin: 0;
}

.logo {
  margin-bottom: 4vh;
}

.coming-soon-container {
  font-family: "Lovelo", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #121212;
  color: white;
  text-align: center;
}

.gradient-text {
  margin: 0;
  font-size: 4rem;
  font-weight: bold;
  background: linear-gradient(90deg, #ffde59, #ff914d);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.info-container {
  margin-top: 4vh;
  font-family: "TW-Cen-MT";
}

footer {
  position: absolute;
  bottom: 2vh;
  text-align: center;
  width: 100%;
}

.social-links a {
  margin: 0 5px;
}

.social-links img {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.social-links img:hover {
  transform: scale(1.2);
  opacity: 0.5;
}
